import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import LanguageSelector from './LanguageSelector';
import * as storage from '../utils/storage';

import close from '../assets/icons/sidebar/close.svg';
import city from '../assets/icons/sidebar/city.svg';
import bonus from '../assets/icons/sidebar/bonus.svg';
import logged from '../assets/icons/sidebar/logged.svg';
import franchise from '../assets/icons/sidebar/franchise.svg';
import login from '../assets/icons/sidebar/login.svg';
import menu from '../assets/icons/sidebar/menu.svg';
import stores from '../assets/icons/sidebar/stores.svg';
import CitySelector from './CitySelector';
import { citiesData } from '../utils/constants';
import { faqPopUpTelegram } from '../assets/icons/svgIcons';

const dayMilliseconds = 24 * 60 * 60 * 1000;

const SidebarContent = ({
  closeMenu, user, languagePrefix, logOut, redirectToMain, translate, currentCity,
}) => {
  const initOpened = !JSON.parse(storage.local.getItem('wasCityChosen'));
  const oldTime = +storage.local.getItem('openedTimeStamp');
  const [openedCityModal, setOpenedCityModal] = useState(initOpened);

  function toggleCityModal(e) {
    if (e) {
      e.preventDefault();
    }
    setOpenedCityModal(!openedCityModal);
    storage.local.setItem('wasCityChosen', 'true');
    storage.local.setItem('openedTimeStamp', Date.now().toString());
  }

  useEffect(() => {
    const now = Date.now();
    const diff = now - oldTime;
    const isMobile = window.innerWidth < 768;
    if (diff > dayMilliseconds && isMobile) {
      setOpenedCityModal(true);
    }
  }, []);

  return (
    <StyledSidebar>
      <header className="sidebar-header">
        <img
          className="icon"
          src={city}
          alt="Marker"
          decoding="async"
          loading="lazy"
        />
        <button className="sidebar-header__city middle" onClick={toggleCityModal}>
          {citiesData?.[currentCity] && translate(citiesData[currentCity].translationName)}
        </button>
        <LanguageSelector />
        <button type="button" className="sidebar-header__btn" onClick={closeMenu} />
      </header>
      <hr className="solid" />
      <nav className="user-nav" onClick={closeMenu}>
        <NavLink className="user-nav__link" to={`${languagePrefix}/`}>
          <img className="icon" src={menu} alt="Menu" decoding="async" loading="lazy" />
          <span className="user-nav__link__name">Меню</span>
        </NavLink>
        <NavLink className="user-nav__link" to={`${languagePrefix}/stores`}>
          <img className="icon" src={stores} alt="Stores" decoding="async" loading="lazy" />
          <span className="user-nav__link__name">{translate('OurStore')}</span>
        </NavLink>
        <a className="user-nav__link" rel="noreferrer" target="_blank" href="https://franchise.budusushi.ua">
          <img className="icon" src={franchise} alt="Franchise" decoding="async" loading="lazy" />
          <span className="user-nav__link__name">Франшиза</span>
        </a>
        {user && user.id ? (
          <div className="user-nav__user-row">
            <NavLink className="user-nav__link" to={`${languagePrefix}/profile`}>
              <img className="icon" src={logged} alt="Login" decoding="async" loading="lazy" />
              <span className="user-nav__link__name">{user.name}</span>
            </NavLink>
            <p
              className="user-nav__logout"
              onClick={() => {
                logOut();
                redirectToMain();
              }}
            >
              {translate('LogOut')}
            </p>
          </div>
        ) : (
          <NavLink className="user-nav__link" to={`${languagePrefix}/authorization`}>
            <img className="icon" src={login} alt="Login" decoding="async" loading="lazy" />
            <span className="user-nav__link__name">{translate('LoginRegistration')}</span>
          </NavLink>
        )}
      </nav>
      <hr className="dashed" />
      <a
        href="https://t.me/Budusushibot"
        className="main-nav__link main-nav__link--help"
        target="_blank"
        rel="noreferrer"
      >
        {faqPopUpTelegram}
        <span>{translate('ChatHelp')}</span>
      </a>
      <hr className="dashed" />
      <nav className="main-nav" onClick={() => closeMenu()}>
        {/* <NavLink className="main-nav__link" to={`${languagePrefix}/pages/about-us`}>{translate('AboutUs')}</NavLink> */}
        <NavLink className="main-nav__link" to={`${languagePrefix}/news`}>{translate('News')}</NavLink>
        <NavLink className="main-nav__link" to={`${languagePrefix}/promotions`}>{translate('Promotions')}</NavLink>
        {/* user && user.id ? (
          <NavLink className="main-nav__link" to={`${languagePrefix}/pages/franchise`}>Франшиза</NavLink>
        ) : null */}
        {/* <NavLink className="main-nav__link" to={`${languagePrefix}/pages/franchise`}>Франшиза</NavLink> */}
        <NavLink className="main-nav__link" to={`${languagePrefix}/vacancies`}>{translate('Vacancies')}</NavLink>
        <NavLink className="main-nav__link" to={`${languagePrefix}/feedback`}>{translate('LinkSupport')}</NavLink>
      </nav>
      <hr className="dashed" />
      <ul className="telephones">
        <li>
          <a href="tel:+380737000808" className="telephones__link">+38 (073) 700-08-08</a>
        </li>
        <li>
          <a href="tel:+380997000808" className="telephones__link">+38 (099) 700-08-08</a>
        </li>
        <li>
          <a href="tel:+380967000808" className="telephones__link">+38 (096) 700-08-08</a>
        </li>
      </ul>
      {openedCityModal && (
        <CitySelector
          modalClose={toggleCityModal}
          translate={translate}
          currentCity={currentCity}
        />
      )}
    </StyledSidebar>
  );
};

export default SidebarContent;

const StyledSidebar = styled.aside`
  --link-color: #E74E1A;
  display: flex;
  flex-direction: column;
  padding-left: 3rem;

  .icon {
    width: 1rem;
    margin-right: 1rem;
  }

  .middle {
    flex-grow: 1;
  }

  > .dashed {
    width: 90%;
    margin: 1.5rem 0;
  }

  .sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4rem;

    &__city {
      color: #fff;
      cursor: pointer;
      background: none;
      padding: 0;
      border: none;
      outline: none;
      text-align: left;
    }

    &__btn {
      cursor: pointer;
      width: 1.2rem;
      height: 1.2rem;
      margin-left: .5rem;
      margin-right: 1.5rem;
      background-image: url(${close});
      background-size: contain;
      background-repeat: no-repeat;
      background-color: transparent;
      border: none;
    }
  }

  .user-nav {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    
    &__user-row {
      display: flex;
      justify-content: space-between;
    }

    &__link {
      color: #eee;
      padding: .75rem 0;
      //flex-basis: 50%;
    }
    
    &__logout {
      margin: 0 2rem 0 1rem;
      color: var(--link-color);
      cursor: pointer;
      display: flex;
      align-items: center;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .main-nav {
    display: flex;
    flex-direction: column;

    &__link {
      color: #777;
      padding: .75rem 0;
      &:hover {
        color: #fff;
      }
      
      &--help {
        color: #e74e1a;
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }
  }

  .telephones {
    li {
      color: #777;
      padding: .75rem 0;
    }
    
    &__link {
      color: inherit;
      &:hover {
        color: #fff;
      }
    }
  }

  @media (min-width: 768px) {
    .sidebar-header {
      height: 6rem;
      &__btn {
         margin-right: 3.6rem;
      }
    }
    
    .user-nav {
      &__logout {
        margin-right: 4.6rem;
      }
    }
  }

  @media (min-width: 1024px) {
    .language {
      display: none;
    }
  }
  
`;
