import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';

import styled from 'styled-components';
import * as actions from '../actions/actions';
import Stores from '../components/stores/Stores';
import getlanguagePrefix from '../modules/shared/getLanguagePrefix';

import storesBanner from '../assets/images/stores/Stores.jpg';
import fixImagePath from '../modules/shared/fixImagePath';


class StoresContainer extends React.Component {
  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    return Promise.all([
      this.props.actions.getStores(),
      this.props.actions.getUser(),
    ]);
  }

  render() {
    const {
      stores, currentLanguage, languagePrefix, settings, translate,
    } = this.props;

    let bannerImagePath = null;

    if (settings && settings.syspages) {
      const pageData = settings.syspages.find(page => page.page_const === 'stores');
      bannerImagePath = fixImagePath(pageData);
    }

    return (
      <StyledDiv className="stores" headerImage={bannerImagePath || storesBanner}>
        <div className="stores__header" />
        <Stores
          stores={stores}
          city={translate('OdessaTown')}
          currentLanguage={currentLanguage}
          languagePrefix={languagePrefix}
          translate={translate}
        />
      </StyledDiv>
    );
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.locale),
  currentLanguage: getActiveLanguage(state.locale).code,
  stores: state.stores ? state.stores : null,
  settings: state.settings ? state.settings : null,
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

const StyledDiv = styled.div`
    background-color: #0A0A0A;
    
  .stores {
    &__header {
      height: 130px;
      text-align: center;
      background: radial-gradient(420.48px at 50.41% 50.71%, rgba(0, 0, 0, 0.1) 0%, #000000 100%),
                  url(${props => (props.headerImage ? props.headerImage : 'null')});
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto 100%;
    }
  }
  
  @media all and (min-width: 769px) { 
  .stores {
    &__header {
      height: 250px;
    }
  }
}
`;

export default connect(mapStateToProps, mapDispatchToProps)(StoresContainer);
