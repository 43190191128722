import React, { Component } from 'react';
import styled from 'styled-components';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import MainBtn from '../shared/MainBtn';
import NewLocationSearchInputLoader from './NewLocationSearchInputLoader';
import * as storage from '../../utils/storage';


import flat from '../../assets/icons/form/flat.svg';
import mapMarker from '../../assets/icons/form/mapMarker.svg';
import entrance from '../../assets/icons/form/entrance.svg';
import code from '../../assets/icons/form/code.svg';
import floor from '../../assets/icons/form/floor.svg';
import * as actions from '../../actions/actions';

const initialAddressState = {
  street: '',
  house_number: '',
  entrance: '',
  flat: '',
  code: '',
  floor: '',
};

const InputField = ({
  label, icon, value, onChange, isCheckout, ...props
}) => (
  <StyledField isCheckout={isCheckout}>
    <input
      id={label}
      type="text"
      value={value}
      autoComplete="off"
      className={`field__input ${value?.length && 'not-empty'}`}
      onChange={onChange}
      {...props}
    />
    <div className="field__icon">
      <img className="field__image" src={icon} alt="icon" decoding="async" loading="lazy" />
    </div>
    <label htmlFor={label} className="field__label">{label}</label>
    <div className="field__line" />
  </StyledField>
);

class NewProfileAddressForm extends Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      address: { ...initialAddressState },
      coordinates: '',
      isAddressChosen: false,
    };
  }

  componentDidMount() {
    const savedAddress = JSON.parse(storage.session.getItem('deliveryAddress'));
    const deliveryData = JSON.parse(storage.session.getItem('addressToEdit'));
    const addressData = !this.props.isCheckout
      ? (!!deliveryData && JSON.parse(deliveryData.address))
      : (!!savedAddress && JSON.parse(savedAddress.address));
    this.setState(() => ({
      address: addressData ? { ...addressData } : { ...initialAddressState },
      coordinates: this.props.isCheckout ? savedAddress?.coordinates : deliveryData?.coordinates,
      isAddressChosen: !!addressData,
    }));
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.isCheckout && nextProps.setDeliveryAddress) {
      this.props.setDeliveryAddress(nextState);
    }
    return true;
  }

  setGoogle = (google) => {
    this.setState(() => ({ google }));
  }

  convertDeliveryZonesFormat = (arr) => {
    const filteredArr = arr.split(';').filter((item) => item);
    return filteredArr.map((item) => {
      const itemElem = item && item.split(',');
      return { lat: parseFloat(itemElem[0]), lng: parseFloat(itemElem[1]) };
    });
  }

  isChosenLocationInDeliveryZones = (loc, address) => {
    const { updateAddress, isProfile } = this.props;

    if (!isProfile) {
      storage.session.setItem('deliveryAddress', JSON.stringify({ ...address, coordinates: `${loc.lat},${loc.lng}` }));
      storage.local.setItem('lastDeliveryAddress', JSON.stringify({ ...address, coordinates: `${loc.lat},${loc.lng}` }));
      storage.local.setItem('lastDeliveryType', JSON.stringify('deliveryAddress'));
      updateAddress();
    }
    if (isProfile) {
      updateAddress({ ...address, coordinates: `${loc.lat},${loc.lng}` });
    }
  }

  onSubmit = (e) => {
    const { street, house_number } = this.state.address;
    e.preventDefault();
    const address = {
      address: JSON.stringify(this.state.address),
      coordinates: this.state.coordinates,
    };
    let fullAddress = street;
    if (house_number) {
      fullAddress += `, ${house_number}`;
    }
    geocodeByAddress(fullAddress)
      .then((response) => {
        if (response.length > 1) {
          return this.props.actions.showSnackBar(this.props.translate('ChooseAddressFromDropdown'), 4000, false);
        }
        const addressResult = response[0].address_components;
        const houseNumber = addressResult.find((item) => (
          item.types.includes('street_number')
        ));
        if (!houseNumber) {
          return this.props.actions.showSnackBar(this.props.translate('AddHouseNumber'), 4000, false);
        }
        return getLatLng(response[0])
          .then((result) => this.isChosenLocationInDeliveryZones(result, address));
      }).catch(() => this.props.actions.showSnackBar(this.props.translate('ChooseAddressFromDropdown'), 4000, false));
  }

  changeStreet = (address) => {
    this.setState((prevState) => ({
      address: {
        ...prevState.address,
        street: address,
        house_number: '',
      },
    }));
  }

  changeHouse = (number) => {
    this.setState((prevState) => ({
      address: {
        ...prevState.address,
        house_number: number,
      },
    }));
  }

  setCoordinates = (coords) => {
    this.setState(() => ({
      coordinates: `${coords.lat}, ${coords.lng}`,
    }));
  }

  setPlaceId = (placeId) => {
    this.setState(() => ({
      placeId,
    }));
  }

  changeFloor = (e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      address: {
        ...prevState.address,
        floor: value,
      },
    }));
  }

  changeEntrance = (e) => {
    const { value } = e.target;

    this.setState((prevState) => ({
      address: {
        ...prevState.address,
        entrance: value,
      },
    }));
  }

  changeCode = (e) => {
    const { value } = e.target;

    this.setState((prevState) => ({
      address: {
        ...prevState.address,
        code: value,
      },
    }));
  }

  changeFlat = (e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      address: {
        ...prevState.address,
        flat: value,
      },
    }));
  }

  render() {
    const {
      translate, isCheckout, isProfile,
    } = this.props;
    const { address } = this.state;

    return (
      <StyledForm
        onSubmit={this.onSubmit}
        isCheckout={isCheckout}
      >
        {isCheckout && (
          <InputField
            value={translate('OdessaTown')}
            icon={mapMarker}
            onChange={this.changeFlat}
            label={translate('YourCity')}
            isCheckout={isCheckout}
            readOnly
          />
        )}
        <div className="address-edit__top">
          <NewLocationSearchInputLoader
            translate={translate}
            street={address.street}
            house={address.house_number}
            changeStreet={this.changeStreet}
            changeHouse={this.changeHouse}
            setCoords={this.setCoordinates}
            setGoogle={this.setGoogle}
            setPlaceId={this.setPlaceId}
            isCheckout={isCheckout}
          />
          {!isCheckout
            ? (
              <MainBtn
                text={translate('Find')}
                minWidth="100px"
                addClass="address-edit__button"
                type="submit"
              />
            ) : null}
        </div>
        {(isCheckout || isProfile) && (
        <div className="address-edit__additional">
          <InputField
            value={address.flat}
            icon={flat}
            onChange={this.changeFlat}
            label="Квартира"
            isCheckout={isCheckout}
          />
          <InputField
            value={address.entrance}
            icon={entrance}
            onChange={this.changeEntrance}
            label={translate('Entrance')}
            isCheckout={isCheckout}
          />
          <InputField
            value={address.floor}
            icon={floor}
            onChange={this.changeFloor}
            label={translate('Floor')}
            isCheckout={isCheckout}
          />
          <InputField
            value={address.code}
            icon={code}
            onChange={this.changeCode}
            label={translate('Code')}
            isCheckout={isCheckout}
          />
        </div>
        )}
      </StyledForm>
    );
  }
}

const StyledForm = styled.form`

  --text-color: ${(props) => (props.isCheckout ? '#616161' : '#111')};
  --border: ${(props) => (props.isCheckout ? '1px solid #616161' : 'none')};
  --input-bg: transparent;
  --field-bg: ${(props) => (props.isCheckout ? '#1c1c1c' : '#fff')};
    
  .address-edit {
    
    &__input {
      background-color: var(--input-bg, #fff);
      border-radius: 4px;
      color: var(--text-color);
      border: none;
      padding: 0 10px;
      height: 42px;
      width: 100%;
    }
    
    &__field {
      background-color: var(--field-bg, transparent);
      border: var(--border, none);
      border-radius: 4px;
      position: relative;
      display: flex;
      align-items: center;
    }
    
    &__label {
      position: absolute;
    }
    
    &__icon {
      width: 60px;
      height: 60px;
      display:flex;
      align-items:center;
      justify-content: center;
    }
    
    &__image {
      opacity: 0.5;
    }
    
    &__top {
      display: flex;
      position: relative;
      margin-bottom: 1rem;
      border-radius: 4px;
      background-color: var(--field-bg, transparent);
      //height: 42px;
      &:nth-child(2) {
        margin-top: 1rem;
      }
    }
    
    &__street {
      flex-grow: 1;
      background-color: transparent;
    }
    
    &__search {
      width: 100%;
    }
    
    &__dropdown-container {
      width: 100%;
    }
    
    &__separator {
      align-self:center;
      height: 80%;
      width: 1px;
      flex-shrink: 0;
      background-color: #ddd;
    }
    
    &__button {
      width: auto;
      margin: 0;
      & > .button {
       border-radius: 0 4px 4px 0;
      }
    }
    
    &__house {
      width: 100px;
    }
    
    &__additional {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;
    }
  }
  
  .hidden-btn {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    z-index: -1000;
  }
`;

const StyledField = styled.div`
  position: relative;
  height: 60px;
  box-sizing: border-box;
  background-color: ${(props) => (props.isCheckout ? '#1C1C1C' : '#fff')};
  border-radius: 4px;
  border: 1px solid ${(props) => (props.error ? 'red' : '#313131')};

  label {
    position: absolute;
    top: 50%;
    left: 70px;
    font-size: 12px;
    color: #616161;
    transform: translateY(-50%);
    transition: all 0.15s ease-in-out;
    transform-origin: left center;
    pointer-events: none;
  }
  input {
    height: 100%;
    background: transparent;
    border: none;
    width: 100%;
    padding-left: 70px;
    color: ${(props) => (props.isCheckout ? '#fff' : '#111')};
    font-size: 15px;
    padding-top: 17px;
    padding-right: 15px;
    outline-color: ${(props) => (props.error ? 'red' : 'transparent')};

    &:focus ~ label,
    &.not-empty ~ label {
      transform: scale(0.85) translateY(-150%);
      color: #616161;
      outline: none;
    }
  }
  
  .field {
    &__line {
      position: absolute;
      top: 50%;
      left: 60px;
      height: 75%;
      width: 2px;
      transform: translateY(-50%);
      background-image: linear-gradient(to top,transparent 66.67%, #313131 33.33%);
      background-size: 100% 8px;
    }
    
    &__icon {
      position: absolute;
      width: 60px;
      height: 60px;
      top: 0;
      left: 0;
      display:flex;
      align-items:center;
      justify-content: center;
    }
    
    &__image {
      opacity: .5;
      ${(props) => !props.isCheckout && 'filter: invert(1);'}
    }
  }


  &.default {
    input {
      padding-left: 15px;
    }
    label {
      left: 77px;
    }
  }
  
  
  @media all and (min-width: 360px) {
     label {
      font-size: 15px;
     }
  }

`;

const mapStateToProps = (state) => ({
  deliveryZones: state.deliveryZones ? state.deliveryZones : null,
  settings: state.settings ? state.settings : null,
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewProfileAddressForm);
